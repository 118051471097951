<template>
    <router-view />
</template>
<script>
import { globalStore } from "@/stores/globalStore";
export default {
    name: "app",
    setup() {
        var store = globalStore();
        return { store };
    },
    created() {
        this.$http.defaults((config) => {
            config.baseURL = process.env.VUE_APP_API_BASE_URL;
            config.timeout = 10000;
            // headers: {
            //     sign: self.$store.state.sign,
            // },
            config.$400 = (err) => {
                console.error(err);
                // this.$alert({
                //     title: "提示",
                //     content: err.response.data ? err.response.data : err.response.statusText,
                // });
            };

            config.$error_network = (err) => {
                console.error("network error ", err);
            };

            config.$finally = () => {
                // self.$hideLoading();
            };
        });
    },
    mounted() {
        window.addEventListener(
            "resize",
            function () {
                // console.log(window.innerWidth);
                this.store.setMobile(window.innerWidth <= 500);
            }.bind(this)
        );
        this.store.setMobile(window.innerWidth <= 500);
    },
};
</script>
<style>
@import url("@/assets/fonts/SourceHanSansCN-Regular/SourceHanSansCN-Regular.css");
@import url("@/assets/fonts/SourceHanSansCN-Normal/SourceHanSansCN-Normal.css");
@import url("@/assets/fonts/SourceHanSansCN-Bold/SourceHanSansCN-Bold.css");
@import url("@/assets/fonts/Akrobat-Bold/Akrobat-Bold.css");
@import url("@/assets/css/global.css");

@import "swiper/css";
</style>
