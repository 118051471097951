<template>
    <transition name="hide-video">
        <div class="video" v-if="showVideo && !store.mobile" @touchmove.prevent @mousewheel.prevent>
            <div>
                <video autoplay="autoplay" muted="muted" src="@/assets/video.mp4" @ended="showVideo = false"></video>
                <p>
                    <a @click="showVideo = false">进入网站</a>
                </p>
            </div>
        </div>
    </transition>
    <div class="pc-wrap" v-show="!showVideo || store.mobile">
        <Wrap class="bg1">
            <div class="row1">
                <Nav></Nav>
                <div class="intro">
                    <div class="pc-intro-line pc-intro-line-light"></div>
                    <p class="pc-intro-tit txt-anim pc-intro-tit-light">数字营销时代的品牌同行者</p>
                    <p class="pc-intro-tit txt-anim pc-intro-tit-light">营销与传播全价值链整合营销服务商</p>
                    <div class="eng pc-intro-english txt-anim pc-intro-tit-light">
                        <section>Brand partner in the digital marketing era.</section>
                        <section class="pc">Integrated marketing service provider across the whole value chain in marketing and communication.</section>
                        <section class="mb">Integrated marketing service provider across the whole</section>
                        <section class="mb">value chain in marketing and communication.</section>
                    </div>
                    <div class="more light-more">
                        <router-link to="/intro">了解更多</router-link>
                    </div>
                    <!-- <div class="down">
                        <img src="@/assets/pc/home/down.png" />
                    </div> -->
                </div>
                <!-- <swiper ref="picSwiper" :loop="true" direction="horizontal" :modules="[Autoplay]" :autoplay="false" :slides-per-view="3" :spaceBetween="0" :height="400" :width="500" :grabCursor="true" :initial-slide="1" :centeredSlides="true">
                <swiper-slide>
                    <div class="slide-item"><img src="@/assets/pc/home/s1.png" /></div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-item"><img src="@/assets/pc/home/s1.png" /></div>
                </swiper-slide>
            </swiper> -->
                <div class="bg-slide" v-if="content">
                    <swiper @swiper="OnSwiper" :loop="true" direction="horizontal" :modules="[Autoplay, Mousewheel]" :autoplay="false" :slides-per-view="1" :spaceBetween="0" :grabCursor="true" @slideChange="slideChange" :mousewheel="false" :passiveListeners="false">
                        <!-- <swiper-slide>
                            <div class="slide-item s1" @touchmove.prevent>
                                <img :src="require(`@/assets/${store.mobile ? 'mb' : 'pc'}/home/s1.png`)" />
                                <h2 v-if="store.mobile">浙江文投背景<br />百亿规模的产业基金</h2>
                                <h2 v-if="!store.mobile">浙江文投背景 百亿规模的产业基金</h2>
                                <div>
                                    <p><span></span>浙文互联集团股份有限公司</p>
                                    <p><span></span>浙江省文化投资集团有限公司</p>
                                    <p><span></span>2021年开启数字文化创新赛道</p>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="slide-item s2" @touchmove.prevent>
                                <img :src="require(`@/assets/${store.mobile ? 'mb' : 'pc'}/home/s2.png`)" />
                                <div class="t1">
                                    <h2>浙文互联集团股份有限公司</h2>
                                    <p><span>•</span>整合营销第一集团军</p>
                                    <p><span>•</span>元宇宙第一股</p>
                                    <p><span>•</span>17家专业子公司协同</p>
                                </div>
                                <div class="t2">
                                    <h2>2021年开启数字文化创新赛道</h2>
                                    <p><span>•</span>数字生活空间构建</p>
                                    <p><span>•</span>短剧与影视IP运营</p>
                                    <p><span>•</span>电竞及赛事运营</p>
                                    <p><span>•</span>元宇宙建设</p>
                                </div>
                                <div class="t3">
                                    <h2>浙江省文化投资集团有限公司</h2>
                                    <p><span>•</span>省属一级国有企业</p>
                                    <p><span>•</span><label>作为浙江省文化产业发展的重要投资主体和投融资主平台</label></p>
                                </div>
                            </div>
                        </swiper-slide> -->
                        <template v-if="!store.mobile">
                            <swiper-slide v-for="item in content.backgroundPc" :key="item.src">
                                <div class="slide-item" @touchmove.prevent>
                                    <img :src="staticHost + item" style="width: 90%" />
                                </div>
                            </swiper-slide>
                        </template>
                        <template v-else>
                            <swiper-slide v-for="item in content.backgroundMobile" :key="item.src">
                                <div class="slide-item" @touchmove.prevent>
                                    <img :src="staticHost + item" style="width: 96%" />
                                </div>
                            </swiper-slide>
                        </template>
                    </swiper>
                </div>
                <div class="bg-slide-page">
                    <div :class="bgSwiperIdx == 0 ? 'active' : ''" @click="toSlide(0)"></div>
                    <div :class="bgSwiperIdx == 1 ? 'active' : ''" @click="toSlide(1)"></div>
                </div>
            </div>
            <!-- <h1>AaxZ</h1> -->
        </Wrap>
        <Wrap v-if="content">
            <template #full>
                <div class="brand" ref="brand">
                    <div class="item">
                        <img src="@/assets/pc/home/brand/1.jpg" />
                        <div>
                            <div class="tit"><countTo :startVal="0" :endVal="2017" separator="" :autoplay="false" ref="countTo1"></countTo>年</div>
                            <p>加入浙文互联</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/pc/home/brand/2.jpg" />
                        <div>
                            <div class="tit"><countTo :startVal="0" :endVal="content.employee" separator="" :autoplay="false" ref="countTo2"></countTo>+位</div>
                            <p>多领域顶尖人士</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/pc/home/brand/3.jpg" />
                        <div>
                            <div class="tit"><countTo :startVal="0" :endVal="content.duration" separator="" :autoplay="false" ref="countTo3"></countTo>+年</div>
                            <p>汽车公关实战经验</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/pc/home/brand/4.jpg" />
                        <div>
                            <div class="tit">
                                <!-- <countTo :startVal="0" :endVal="8" separator="" :autoplay="false" ref="countTo4"></countTo> -->
                                <div class="sp">∞</div>
                                +
                            </div>
                            <p>
                                多元复合型团队人才<br />
                                汽车/金融/快消
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/pc/home/brand/5.jpg" />
                        <div>
                            <div class="tit"><countTo :startVal="0" :endVal="content.partner" separator="" :autoplay="false" ref="countTo5"></countTo>+家</div>
                            <p>世界500强企业合作伙伴</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/pc/home/brand/6.jpg" />
                        <div>
                            <div class="tit"><countTo :startVal="0" :endVal="content.branchCompany" separator="" :autoplay="false" ref="countTo6"></countTo>地</div>
                            <p v-html="content.branchCompanyArea"></p>
                        </div>
                    </div>
                </div>
            </template>
        </Wrap>

        <Wrap class="bg2">
            <div class="honor">
                <div class="detail">
                    <p class="txt-anim pc">全域赋能 稳居营销传播第一阵营</p>
                    <p class="txt-anim mb">全域赋能<br />稳居营销传播第一阵营</p>
                    <ul>
                        <li><span></span><router-link to="/about">行业资质</router-link></li>
                        <li><span></span><router-link to="/about?pos=honor">荣誉奖项</router-link></li>
                    </ul>
                    <div class="more">
                        <router-link to="/about">了解更多</router-link>
                    </div>
                </div>
                <!-- <div class="logos" v-if="customer.pcCustomer"><img :src="staticHost + customer.pcCustomer" /></div> -->
            </div>
        </Wrap>
        <Wrap class="bg3">
            <div class="logos customer-mobile" v-if="customer.mobileCustomer"><img :src="staticHost + customer.mobileCustomer" /></div>
            <div class="logos customer-pc" v-if="customer.pcCustomer"><img :src="staticHost + customer.pcCustomer" /></div>
        </Wrap>
        <Footer></Footer>
    </div>
</template>
<script>
/*eslint-disable */
import Nav from "../../components/pc/nav.vue";
import Wrap from "../../components/pc/wrap.vue";
import Footer from "../../components/pc/footer.vue";
import { CountTo } from "vue3-count-to";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Mousewheel } from "swiper";
import { globalStore } from "@/stores/globalStore";
export default {
    name: "pc-home",
    components: { Nav, Wrap, Swiper, SwiperSlide, Footer, CountTo },
    data() {
        return {
            bgSwiperIdx: 0,
            countTo: false,
            customer: {},
            staticHost: process.env.VUE_APP_STATIC_BASE,
            showVideo: !this.$route.query.fm,
            content: null,
        };
    },
    setup() {
        var store = globalStore();
        return { Autoplay, Mousewheel, store };
    },
    mounted() {
        window.scrollTo(0, 0);
        document.addEventListener(
            "scroll",
            function () {
                if (this.$route.name != "home") return;
                var scrollTop = document.documentElement.scrollTop;

                if (!this.$refs["brand"]) return;
                var pos = this.$refs["brand"].offsetTop;
                var windowHeight = window.innerHeight;
                if (!this.countTo && Math.abs(scrollTop - pos) < windowHeight - 200) {
                    this.countTo = true;
                    this.$refs["countTo1"].start();
                    this.$refs["countTo2"].start();
                    this.$refs["countTo3"].start();
                    // this.$refs["countTo4"].start();
                    this.$refs["countTo5"].start();
                    this.$refs["countTo6"].start();
                }
            }.bind(this)
        );
        // this.$nextTick(() => {
        //     window.scrollTo(0, document.documentElement.scrollHeight);
        // });
        // setInterval(() => {
        //     window.scrollTo(0, document.documentElement.scrollHeight);
        // }, 5000);
        this.loadData();
    },
    methods: {
        OnVideoEndedHandle() {},
        OnSwiper(swiper) {
            this.swiper = swiper;
        },
        slideChange(e) {
            // console.log(e);
            this.bgSwiperIdx = e.realIndex;
        },
        toSlide(idx) {
            this.swiper.slideTo(idx);
        },
        loadData() {
            this.$http.get("/api/index/customer").then((res) => {
                this.customer = res.data;
            });
            this.$http.get("/api/index/data").then((res) => {
                this.content = res.data;
            });
        },
    },
};
</script>
<style scoped>
@import url("@/assets/css/pc.global.css");

@keyframes scale-out-vertical {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1;
    }
}

.hide-video-leave-active {
    animation: scale-out-vertical 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.video {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999999;
}
.video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video div {
    position: relative;
    width: 100%;
    height: 100%;
}
.video div p {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
}
.video div p a {
    font-size: 18px;
    color: #004e98;
    text-decoration: underline;
    cursor: pointer;
}
.intro {
    font-family: SourceHanSansCN-Normal;
}
.more a:hover {
    background: #004e98;
    color: #fff;
}

.bg-slide-page div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px #0059a1 solid;
    margin: 0 8px;
    cursor: pointer;
}
.bg-slide-page .active {
    background: #0059a1;
}

.slide-item {
    display: flex;
    align-items: center;
    justify-content: center;
}
@keyframes jump {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 500px) {
    .s1,
    .s2 {
        position: relative;
        font-family: SourceHanSansCN-Regular;
    }
    .s1 h2 {
        position: absolute;
        left: 50%;
        top: 45%;
        font-size: 13px;
        font-weight: 500;
        color: #0058a2;
        z-index: 10;
    }
    .s1 div {
        position: absolute;
        left: 50%;
        top: 62%;
    }
    .s1 p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px 0;
        font-size: 12px;
        font-weight: 500;
    }
    .s1 span {
        width: 6px;
        height: 2px;
        background: #7dcbe9;
        margin-right: 3px;
    }
    .s2 div {
        position: absolute;
    }
    .s2 .t1 {
        left: 0;
        top: 5%;
    }
    .s2 .t2 {
        left: 0;
        top: 67%;
    }
    .s2 .t3 {
        left: 53%;
        top: 14%;
    }

    .s2 div h2 {
        font-size: 12px;
        font-weight: 500;
        color: #0058a2;
        margin-bottom: 0px;
        line-height: 16px;
        margin-bottom: 3px;
    }
    .s2 div p {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        display: flex;
    }
    .s2 div p span {
        font-size: 14px;
        margin-left: -5px;
    }
    .s2 .t3 .sp {
        display: flex;
    }
    .pc {
        display: none;
    }
    .bg1 {
        background: url("~@/assets/mb/home/bg.1.png") no-repeat top center;
    }
    .bg3 {
        background: url("~@/assets/mb/home/bg.2.jpg") no-repeat bottom center;
    }
    .row1 {
        /* height: calc(782 * 100vw / 750 + 100vw * 1); */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .intro {
        margin: calc(100vw * 0.2) 0 0 15px;
        height: calc(100vw * 0.6);
    }
    .intro .pc-intro-line {
        display: none;
    }

    .intro .more {
        margin-top: calc(100vw * 0.1);
    }
    .intro .more a {
        font-family: SourceHanSansCN-Regular;
        border: 0.5px #fff solid;
        color: #fff;
        font-size: 12px;
        padding: 3px 25px;
        border-radius: 20px;
        background: transparent;
    }
    .more a:hover {
        background: #fff;
        color: #004e98;
    }
    .down {
        margin-top: 80px;
        animation: jump 2s ease-in-out infinite;
    }
    .down img {
        width: 14px;
        margin-left: 4px;
    }
    .bg-slide {
        width: calc(100vw - 30px);
        height: calc(600 * (100vw -30) / 692);
        margin: calc(100vw * 0.2) 15px 10px 15px;
    }
    .bg-slide-page {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .bg-slide-page div {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 1px #0059a1 solid;
        margin: 0 4px;
        cursor: pointer;
    }

    .honor {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        background: url("~@/assets/pc/home/honor.png") no-repeat center right;
        background-size: 80% auto;
        /* background-position-x: calc(100vw * 0.8 - 15px); */
        height: calc(100vw * 1.1);
    }
    .honor .detail {
        margin: calc(100vw * 0.3) 0 0 15px;
    }
    .honor .detail p {
        font-size: 16px;
        margin-bottom: 45px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 600;
        color: #0059a1;
    }

    .honor .detail ul li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular;
        margin-bottom: 10px;
    }
    .honor .detail ul li span {
        display: inline-block;
        width: 8px;
        height: 3px;
        background: #004e98;
        margin-right: 10px;
    }
    .honor .more {
        margin-top: calc(100vw * 0.1);
    }
    .honor .more a {
        font-family: SourceHanSansCN-Regular;
        border: 1px #6e6e6e solid;
        color: #333;
        font-size: 12px;
        padding: 3px 25px;
        border-radius: 20px;
        background: transparent;
    }

    .logos {
        display: block;
        height: calc(342 * 100vw / 764);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logos img {
        width: calc(100vw - 30px);
    }
    .customer-pc {
        display: none;
    }
    .brand {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
    }
    .brand .item {
        position: relative;
    }
    .brand .item > div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .brand .item > div p {
        font-family: SourceHanSansCN-Regular;
        text-align: center;
    }
    .brand .item > div .tit {
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .brand .item > div .tit span {
        font-family: Akrobat-Bold;
        font-size: 35px;
        line-height: 35px;
        font-weight: bold;
        margin-right: 4px;
    }
    .brand .item > div p {
        font-size: 12px;
    }
    .sp {
        font-size: 50px;
        font-weight: normal;
        transform: translateY(-1px);
        font-family: Akrobat-Bold;
    }
    .honor .detail ul li a:hover {
        color: #004e98;
        text-decoration: underline;
    }
}
@media screen and (min-width: 501px) and (max-width: 2050px) {
    .s1,
    .s2 {
        position: relative;
        font-family: SourceHanSansCN-Regular;
    }
    .s1 h2 {
        position: absolute;
        left: 68.5%;
        top: 48.5%;
        font-size: 18px;
        font-weight: 500;
        color: #0058a2;
        z-index: 10;
    }
    .s1 div {
        position: absolute;
        left: 68.5%;
        top: 53.5%;
    }
    .s1 p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 13% 0;
        font-size: 15px;
        font-weight: 500;
    }
    .s1 span {
        width: 10px;
        height: 3px;
        background: #7dcbe9;
        margin-right: 5px;
    }
    .s2 div {
        position: absolute;
    }
    .s2 .t1 {
        left: 0;
        top: 0;
    }
    .s2 .t2 {
        left: 0;
        top: 73%;
    }
    .s2 .t3 {
        left: 74%;
        top: 8%;
    }
    .s2 div h2 {
        font-size: 18px;
        font-weight: 500;
        color: #0058a2;
        margin-bottom: 12%;
    }
    .s2 div p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 3px;
        line-height: 35px;
        display: flex;
    }
    .s2 div p span {
        margin-right: 4px;
    }
    .light-more a {
        border-color: transparent !important;
        background: #fff;
    }
    .mb {
        display: none;
    }
    .bg1 {
        background: url("~@/assets/pc/home/bg.1.jpg") no-repeat top center;
    }
    .bg3 {
        background: url("~@/assets/pc/home/bg.2.jpg") no-repeat bottom center;
    }
    .brand {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
    }
    .brand .item {
        position: relative;
    }
    .brand .item > div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .brand .item .tit span {
        font-family: Akrobat-Bold;
    }
    .brand .item > div p {
        font-family: SourceHanSansCN-Regular;
        text-align: center;
    }
    .honor .detail ul li a:hover {
        color: #004e98;
        text-decoration: underline;
    }
    .sp {
        font-size: 80px;
        font-weight: normal;
        transform: translateY(-8px);
    }
    .row1 {
        height: 1603px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .intro {
        margin-top: 240px;
    }

    .more {
        margin-top: 80px;
    }
    .more a {
        font-family: SourceHanSansCN-Regular;
        border: 1px #4d4d4d solid;
        color: #4d4d4d;
        font-size: 13px;
        padding: 4px 25px;
        border-radius: 20px;
    }
    .down {
        margin-top: 80px;
        animation: jump 2s ease-in-out infinite;
    }
    .down img {
        width: 14px;
        margin-left: 4px;
    }
    .bg-slide {
        width: 100%;
        margin-top: 300px;
    }
    .bg-slide-page {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
    .brand .item > div .tit {
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .brand .item > div .tit span {
        font-size: 60px;
        line-height: 40px;
        font-weight: bold;
        margin-right: 4px;
    }
    .brand .item > div p {
        font-size: 15px;
    }
    .honor {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        background: url("~@/assets/pc/home/honor.png") no-repeat top right;
        background-size: 65% auto;
        background-position-y: 100px;
        height: 850px;
    }
    .honor .detail {
        margin-top: 350px;
    }
    .honor .detail p {
        font-size: 22px;
        margin-bottom: 45px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 600;
        color: #0059a1;
    }

    .honor .detail ul li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular;
        margin-bottom: 20px;
    }
    .honor .detail ul li span {
        display: inline-block;
        width: 8px;
        height: 3px;
        background: #004e98;
        margin-right: 10px;
    }

    .logos {
        display: block;
        height: 467px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .customer-mobile {
        display: none;
    }
}

@media screen and (min-width: 2050px) {
    .s1,
    .s2 {
        position: relative;
        font-family: SourceHanSansCN-Regular;
    }
    .s1 h2 {
        position: absolute;
        left: 68.5%;
        top: 48.5%;
        font-size: 18px;
        font-weight: 500;
        color: #0058a2;
        z-index: 10;
    }
    .s1 div {
        position: absolute;
        left: 68.5%;
        top: 53.5%;
    }
    .s1 p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 13% 0;
        font-size: 15px;
        font-weight: 500;
    }
    .s1 span {
        width: 10px;
        height: 3px;
        background: #7dcbe9;
        margin-right: 5px;
    }
    .s2 div {
        position: absolute;
    }
    .s2 .t1 {
        left: 0;
        top: 2%;
    }
    .s2 .t2 {
        left: 0;
        top: 75%;
    }
    .s2 .t3 {
        left: 83%;
        top: 9.5%;
    }
    .s2 div h2 {
        font-size: 18px;
        font-weight: 500;
        color: #0058a2;
        margin-bottom: 12%;
    }
    .s2 div p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 3px;
        line-height: 35px;
    }
    .s2 div p span {
        margin-right: 4px;
    }

    .light-more a {
        border-color: transparent !important;
        background: #fff;
    }
    .mb {
        display: none;
    }
    .bg1 {
        background: url("~@/assets/pc/home/bg.1.jpg") no-repeat top center;
    }
    .bg3 {
        background: url("~@/assets/pc/home/bg.2.jpg") no-repeat bottom center;
    }
    .brand {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
    }
    .brand .item {
        position: relative;
    }
    .brand .item > div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .brand .item .tit span {
        font-family: Akrobat-Bold;
    }
    .brand .item > div p {
        font-family: SourceHanSansCN-Regular;
        text-align: center;
    }
    .honor .detail ul li a:hover {
        color: #004e98;
        text-decoration: underline;
    }
    .sp {
        font-size: 80px;
        font-weight: normal;
        transform: translateY(-8px);
    }
    .row1 {
        height: 3004px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .intro {
        margin-top: 500px;
    }

    .more {
        margin-top: 150px;
    }
    .more a {
        font-family: SourceHanSansCN-Regular;
        border: 1px #4d4d4d solid;
        color: #4d4d4d;
        font-size: 14px;
        padding: 4px 25px;
        border-radius: 20px;
    }
    .down {
        margin-top: 100px;
    }
    .down img {
        width: 20px;
        margin-left: 4px;
        animation: jump 2s ease-in-out infinite;
    }
    .bg-slide {
        width: 100%;
        margin-top: 800px;
    }
    .bg-slide-page {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
    .brand .item > div .tit {
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .brand .item > div .tit span {
        font-size: 75px;
        line-height: 50px;
        font-weight: bold;
        margin-right: 4px;
    }
    .brand .item > div p {
        font-size: 15px;
    }

    .honor {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        background: url("~@/assets/pc/home/honor.png") no-repeat top right;
        background-size: 65% auto;
        background-position-y: 150px;
        height: 1300px;
    }
    .honor .detail {
        margin-top: 450px;
    }
    .honor .detail p {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        font-family: SourceHanSansCN-Regular;
        color: #0059a1;
    }

    .honor .detail ul li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular;
        margin-bottom: 20px;
    }
    .honor .detail ul li span {
        display: inline-block;
        width: 8px;
        height: 3px;
        background: #004e98;
        margin-right: 10px;
    }
    .logos {
        height: 876px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .customer-mobile {
        display: none;
    }
}

/* @keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        letter-spacing: normal;
        opacity: 1;
    }
} */
</style>
