<template>
    <div class="nav light" :class="showBack ? 'nav-bg' : ''">
        <img :src="require(`@/assets/logo-${logo}.png`)" class="logo" v-if="!showBack" />
        <i class="icon-amaxz icon-back" v-else @click="$router.go(-1)"></i>
        <div v-if="store.mobile" class="title">{{ title }}</div>
        <div class="menu-btn icon-amaxz icon-menu" @click="showMenus = true"></div>
        <div class="menus-task" @touchmove.prevent="showMenus = false" v-if="showMenus"></div>

        <ul class="menus" :class="{ showMenus: showMenus }" @touchmove.prevent>
            <li>
                <router-link to="/?fm=1"
                    >首页<span>HOME</span>
                    <div></div
                ></router-link>
            </li>

            <li>
                <router-link to="/intro"
                    >AmaxZ<span>INTRODUCTION</span>
                    <div></div
                ></router-link>
            </li>

            <li>
                <router-link to="/case"
                    >案例<span>CASE</span>
                    <div></div
                ></router-link>
            </li>

            <li>
                <router-link to="/news"
                    >新闻<span>NEWS</span>
                    <div></div
                ></router-link>
            </li>

            <li>
                <router-link to="/recruitment"
                    >招聘<span>RECRUITMENT</span>
                    <div></div
                ></router-link>
            </li>
        </ul>

        <a class="icon-amaxz icon-close" :class="{ showMenus: showMenus }" @click="showMenus = false"></a>
    </div>
</template>

<script>
import { globalStore } from "@/stores/globalStore";
export default {
    name: "pc-nav",
    props: {
        logo: {
            type: String,
            default: "light",
        },
        showBack: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
    },
    setup() {
        var store = globalStore();
        return { store };
    },
    data() {
        return {
            showMenus: false,
        };
    },
};
</script>

<style scoped>
.menus {
    display: inline-flex;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 500px) {
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: SourceHanSansCN-Regular;
        padding: 12px 15px 12px 15px;
        position: relative;
        width: calc(100vw - 30px);
    }
    .nav-bg {
        background: #0059a1;
    }
    .logo {
        width: 18%;
    }
    .icon-back {
        font-size: 20px;
        color: #fff;
        font-weight: bolder;
    }
    .title {
        font-size: 16px;
        font-weight: 500;
    }
    .menu-btn {
        color: #fff;
        font-size: 25px;
        font-weight: 500;
    }
    .menus-task {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.2);
        z-index: 9998;
    }
    .menus {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        background: #f6f6f6;
        width: 80% !important;
        z-index: 9999;
        align-items: center !important;
        justify-content: flex-start !important;
        padding-top: 60px;
        transform: translateX(150%);
        /* opacity: 0; */
    }
    .menus li {
        width: 90%;
        border-bottom: 1px #e5e5e5 solid;
    }
    .menus li a {
        display: flex;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
        color: #333;
    }
    .menus li a span {
        /* margin-top: 4px; */
        font-size: 13px;
        color: #333;
    }

    .menus li a div {
        display: none;
    }
    .icon-close {
        color: #333 !important;
        position: fixed;
        top: 15px;
        right: 15px;
        font-size: 30px;
        z-index: 99999;
        transform: translateX(150%);
    }
    .router-link-active,
    .router-link-active * {
        color: #004e98 !important;
        font-weight: normal;
    }
    .router-link-active {
        font-weight: bold !important;
    }
    /* .menu-fade-enter-active, */
    .showMenus {
        animation: menu-fade-in 0.3s ease-in-out forwards;
    }
    /* .menu-fade-leave-active, */
    .hideMenus {
        animation: menu-fade-in 0.3s ease-in-out forwards reverse !important;
    }
    @keyframes menu-fade-in {
        0% {
            opacity: 0.3;
            transform: translateX(150%);
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

@media screen and (min-width: 501px) and (max-width: 2050px) {
    .menu-btn,
    .menus-task,
    .icon-close {
        display: none;
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: SourceHanSansCN-Regular;
        margin-top: 30px;
        width: 100%;
    }

    .logo {
        width: 140px;
    }
    .menus li a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
    }
    .menus li a span {
        /* margin-top: 4px; */
        font-size: 13px;
    }

    .menus li a div {
        width: 20px;
        height: 3px;
        background: transparent;
        margin-top: 6px;
    }
    .router-link-active div {
        background: #fff;
    }
}

@media screen and (min-width: 2050px) {
    .menu-btn,
    .menus-task,
    .icon-close {
        display: none;
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: SourceHanSansCN-Regular;
        margin-top: 30px;
        width: 100%;
    }

    .logo {
    }
    .menus li a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }
    .menus li a span {
        margin-top: 6px;
    }

    .menus li a div {
        width: 25px;
        height: 4px;
        background: transparent;
        margin-top: 6px;
    }
    .blue .router-link-active div {
        background: #004e98;
    }
}

.blue a:hover div {
    background: #004e98;
}
.light .router-link-active div {
    background: #fff;
}
.light a:hover div {
    background: #fff;
}
.light * {
    color: #fff;
}
</style>
