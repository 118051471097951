<template>
    <!-- :style="`background:url(${bg});${bgAttr}${style}${height}`" -->
    <div class="pc-row-wrap" :style="`${style}`">
        <div class="full-contaier">
            <slot name="full"></slot>
        </div>
        <div class="pc-row">
            <div class="container">
                <slot> </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pc-wrap",
    props: {
        style: { type: String, default: "" },
    },
};
</script>

<style scoped>
.full-contaier {
    width: 100%;
}

@media screen and (max-width: 500px) {
    .container {
        text-align: left;
    }
    .pc-row-wrap {
        background-size: 100% auto !important;
    }
    .pc-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        width: 100vw;
    }
    /* .pc-row-bg {
        width: 100%;
        min-width: 1800px;
    } */
    .container {
        width: 100vw;
        overflow-x: hidden;
    }
}

@media screen and (min-width: 501px) and (max-width: 2050px) {
    .container {
        max-width: 1500px;
        min-width: 960px;
        text-align: left;
    }
    .pc-row-wrap {
        background-size: 2050px auto !important;
    }
    .pc-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 960px;
        width: 100%;
    }
    /* .pc-row-bg {
        width: 100%;
        min-width: 1800px;
    } */
    .container {
        width: 960px;
    }
}

@media screen and (min-width: 2050px) {
    .container {
        max-width: 1500px;
        min-width: 960px;
        text-align: left;
    }
    .pc-row-wrap {
        background-size: 3840px auto !important;
    }
    .pc-row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 1500px;
        width: 100%;
    }

    .container {
        width: 1500px;
    }
}
</style>
