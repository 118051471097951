<template>
    <Wrap>
        <div class="footer-wrap">
            <div class="footer">
                <div class="left">
                    <div class="group pc">
                        <h4>关于我们</h4>
                        <ul>
                            <li><router-link to="/intro">公司介绍</router-link></li>
                            <li><router-link to="/about?pos=honor">AmaxZ荣誉</router-link></li>
                        </ul>
                    </div>
                    <div class="group">
                        <h4 class="pc">联系我们</h4>
                        <ul>
                            <li><img src="@/assets/pc/footer/tel.png" />TEL:010-87835788</li>
                            <li><img src="@/assets/pc/footer/email.png" />Email:hr@amaxz.com.cn</li>
                            <li><img src="@/assets/pc/footer/addr.png" />北京市朝阳区高碑店乡高井文化园路8号<br />东亿国际传媒产业园区二期元君书苑F1号楼</li>
                        </ul>
                    </div>
                    <div class="group footer-nav pc">
                        <ul>
                            <li><router-link to="/?fm=1">首页</router-link></li>
                            <li>|</li>
                            <li><router-link to="/intro">AmaxZ</router-link></li>
                            <li>|</li>
                            <li><router-link to="/case">案例</router-link></li>
                            <li>|</li>
                            <li><router-link to="/news">新闻</router-link></li>
                            <li>|</li>
                            <li><router-link to="/recruitment">招聘</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="right">
                    <img src="@/assets/qr.jpg" />
                    AmaxZ官方公众号
                </div>
            </div>
            <div class="copyright">版权所有：浙文天杰 www.amaxz.com.cn 京ICP备2022034301号</div>
            <!-- <div>{{ width }}*{{ height }}</div> -->
        </div>
    </Wrap>
</template>
<script>
import Wrap from "./wrap.vue";
export default {
    name: "pc-footer",
    components: { Wrap },
    props: {
        showLine: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            width: window.screen.width,
            height: window.screen.height,
        };
    },
};
</script>
<style scoped>
@media screen and (max-width: 500px) {
    .pc {
        display: none !important;
    }
    .footer {
        font-family: SourceHanSansCN-Regular;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 15px;
    }
    .left {
        flex: 1;
        display: flex;
    }
    .group {
        margin-right: 30px;
    }
    .group h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .group ul li {
        font-size: 12px;
        margin-top: 10px;
        line-height: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .group ul li a:hover {
        text-decoration: underline;
        color: #004e98;
    }
    .group ul li img {
        width: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
    .right img {
        width: 70px;
    }
    .copyright {
        color: #b9b9b9;
        /* margin-top: 10px; */
        margin: 10px 15px;
        font-size: 12px;
    }
    .footer-wrap {
        padding: 30px 0;
    }
}

@media screen and (min-width: 501px) {
    .mb {
        display: none;
    }
    .footer {
        font-family: SourceHanSansCN-Regular;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .left {
        flex: 1;
        display: flex;
    }
    .group {
        margin-right: 30px;
    }
    .group h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .group ul li {
        font-size: 13px;
        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .group ul li a:hover {
        text-decoration: underline;
        color: #004e98;
    }
    .group ul li img {
        width: 16px;
        margin-right: 10px;
    }
    .footer-nav {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }
    .footer-nav ul {
        display: flex;
    }
    .footer-nav ul li {
        margin: 0 6px;
        font-size: 14px;
    }
    .footer-nav ul li a:hover {
        text-decoration: underline;
        color: #004e98;
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .right img {
        width: 110px;
    }
    .copyright {
        color: #b9b9b9;
        margin-top: 10px;
    }
    .footer-wrap {
        padding: 100px 0;
    }
}
</style>
