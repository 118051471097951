import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import countTo from "vue3-count-to";
import http from "@zeelyn/http";
import { createPinia } from "pinia";
const app = createApp(App);
app.config.globalProperties.$http = http;
app.use(countTo);
app.use(createPinia());
app.use(router).mount("#app");
