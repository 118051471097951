import { defineStore } from "pinia";
export const globalStore = defineStore("globalStore", {
    state: () => ({
        mobile: false,
        case_category: {
            loaded: false,
            list: [],
        },
    }),
    actions: {
        setCaseCategory(val) {
            this.case_category.loaded = true;
            this.case_category.list = val;
        },
        setMobile(val) {
            this.mobile = val;
        },
    },
});
