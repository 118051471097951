import { createRouter, createWebHistory } from "vue-router";
import PCHome from "../views/pc/Home.vue";

const routes = [
    {
        path: "/",
        name: "home",
        // component: PCHome,
        children: [
            {
                component: PCHome,
                path: "/",
                name: "home",
                meta: {
                    title: "浙文天杰",
                },
            },
            {
                component: () => import("../views/pc/About.vue"),
                path: "/about",
                name: "about",
                meta: {
                    title: "关于我们",
                },
            },
        ],
    },
    {
        path: "/intro",
        name: "intro",
        meta: {
            title: "公司介绍",
        },
        component: () => import("../views/pc/Intro.vue"),
    },
    {
        // component: () => import("../views/pc/Case.vue"),
        path: "/case",
        name: "case",
        meta: {
            title: "案例",
        },
        children: [
            {
                component: () => import("../views/pc/Case.vue"),
                path: "/case",
                name: "case",
                meta: {
                    title: "案例",
                },
            },
            {
                component: () => import("../views/pc/CaseCategory.vue"),
                path: "/case/:id",
                name: "case_category",
                meta: {
                    title: "案例",
                },
            },
            {
                component: () => import("../views/pc/CaseDetail.vue"),
                path: "/case/detail/:id",
                name: "case_detail",
                meta: {
                    title: "案例",
                },
            },
        ],
    },
    {
        // component: () => import("../views/pc/Case.vue"),
        path: "/news",
        name: "news",
        meta: {
            title: "新闻",
        },
        children: [
            {
                component: () => import("../views/pc/News.vue"),
                path: "/news",
                name: "news",
                meta: {
                    title: "新闻",
                },
            },

            {
                component: () => import("../views/pc/NewsDetail.vue"),
                path: "/news/:id",
                name: "news_detail",
                meta: {
                    title: "新闻",
                },
            },
        ],
    },
    {
        path: "/recruitment",
        name: "recruitment",
        meta: {
            title: "加入我们",
        },
        component: () => import("../views/pc/Recruitment.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});
export default router;
